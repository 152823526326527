import styled from "styled-components";

const PocketWrapper = styled.div`
  padding: 40px 0;
  img {
    margin: 0 auto;
    padding: 0 5px;
  }
  .underline {
    -webkit-text-decoration: underline;
    text-decoration: underline;
    -webkit-text-decoration-color: #575fa2;
    text-decoration-color: #575fa2;
    text-decoration-thickness: 2px;
  }
  .table {
    // width: 100%;
  }

  td,
  #costofowner th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #costofowner tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  #costofowner td:nth-child(1) {
    background-color: #ffc1c6;
  }
  #costofowner th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #545b9f;
    color: white;
  }
  th {
    text-align: left;
  }
  .novisible {
    opacity: 0;
  }
  .colorbg {
    background-color: #ffc1c6;
  }
  .bottommargin {
    margin-bottom: 50px;
  }
  .dollar {
    display: flex;
    align-items: center;
    input {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  .center {
    text-align: center;
  }
  .underline {
    text-decoration: underline;
  }
  .nopcdisplay {
    display: none;
  }
  .nomobile_display {
    display: block;
  }
  .noborder {
    border: none;
  }
  input:read-only {
    pointer-events: none;
    text-align: center;
  }
  @media only screen and (max-width: 992px) {
    .nopcdisplay {
      display: block;
    }
    .nomobile_display {
      display: none;
    }
    input {
      width: 100%;
    }
  }
`;

export default PocketWrapper;
