import React, { useState } from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import Button from "common/src/components/Button";
import Image from "common/src/components/Image";
import Container from "common/src/components/UI/Container";
import { useStaticQuery, graphql, Link } from "gatsby";

import CalculatorWrapper from "./calculator.style.js";

const CalculatorOthersection = ({
  row,
  col50,
  title,
  textArea,
  description,
  smalldescription,
  mediumdescription,
}) => {
  const capitalExpenses = {
    initialDevelopmentCost: "",
    costofAI: "",
  };
  const operatingExpenses = {
    rpaLicensingCost_cost: "",
    aiLicensingCost_cost: "",
    infraCost_cost: "",
    supportCost_cost: "",

    rpaLicensingCost_year: "",
    aiLicensingCost_year: "",
    infraCost_year: "",
    supportCost_year: "",
  };
  const fundamentalConsiderations = {
    averageEmployeeCostPerHour: "",
    employeeHoursForOneIteration: "",
    numberOfIterationsPerYear: "",
    hoursPerYear: "",
    overtimeHours: "",
  };
  const organizationalSavings = {
    reducedLabourCost_cost: "",
    peakTimeAccomodation_cost: "",
    overtimeReduction_cost: "",
    otherBenefits_cost: "",
    reskilling_cost: "",
    boosting_cost: "",
    improvedDataQuality_cost: "",
    improvedRegulatoryCompliance_cost: "",

    reducedLabourCost_years: "",
    peakTimeAccomodation_years: "",
    overtimeReduction_years: "",
    otherBenefits_years: "",
    reskilling_years: "",
    boosting_years: "",
    improvedDataQuality_years: "",
    improvedRegulatoryCompliance_years: "",
  };
  const totalsavingcalc = {
    totalCost: "",
    totalsavings: "",
    costLesssavings: "",
  };
  const [state_capitalExpenses, set_capitalExpenses] = useState(
    capitalExpenses
  );
  const [state_operatingExpenses, set_operatingExpenses] = useState(
    operatingExpenses
  );
  const [
    state_fundamentalConsiderations,
    set_fundamentalConsiderations,
  ] = useState(fundamentalConsiderations);

  const [state_organizationalSavings, set_organizationalSavings] = useState(
    organizationalSavings
  );

  const handlecapitalExpenses = (e) => {
    const { name, value } = e.target;
    const intvalue = parseInt(value) > 0 ? parseInt(value) : 0;
    set_capitalExpenses({
      ...state_capitalExpenses,
      [name]: intvalue,
    });
  };
  const handleoperatingExpenses = (e) => {
    const { name, value } = e.target;
    const intvalue = parseInt(value) > 0 ? parseInt(value) : 0;
    set_operatingExpenses({
      ...state_operatingExpenses,
      [name]: intvalue,
    });
  };
  const handlefundamentalConsiderations = (e) => {
    const { name, value } = e.target;
    const intvalue = parseInt(value) > 0 ? parseInt(value) : 0;
    set_fundamentalConsiderations({
      ...state_fundamentalConsiderations,
      [name]: intvalue,
    });
  };
  const handleorganizationalSavings = (e) => {
    const { name, value } = e.target;
    // const intvalue = parseInt(value) || "";
    const intvalue = parseInt(value) > 0 ? parseInt(value) : 0;
    set_organizationalSavings({
      ...state_organizationalSavings,
      [name]: intvalue,
    });
  };
  return (
    <CalculatorWrapper>
      {/* Capital Expense */}
      <Container className="bottommargin">
        <Heading {...title} content="COST OF OWNERSHIP" />
        <Heading {...description} content="CAPITAL EXPENSES" />
        <Box {...row}>
          <Box {...col50}>
            <Text content="Initial Development Costs" />
          </Box>
          <Box {...col50}>
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="initialDevelopmentCost"
                value={state_capitalExpenses.initialDevelopmentCost}
                onChange={handlecapitalExpenses}
              ></input>
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text content="cost of AI, RPA bots, internal and external costs to implement it into your system" />
          </Box>
          <Box {...col50}>
            {/* bind input */}
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="costofAI"
                value={state_capitalExpenses.costofAI}
                onChange={handlecapitalExpenses}
              ></input>
            </span>
          </Box>
        </Box>
        <Heading {...description} content="OPERATING EXPENSES" />
        <Text
          {...smalldescription}
          content="Multiply by number of years organization expects to use the bot (include additional factor for continual inflationary adjustments for costs above Year 1 costs)"
        />
        <div className="nomobile_display">
          <table className="table" id="costofowner">
            <thead>
              <tr>
                <th style={{ backgroundColor: "transparent" }}></th>
                <th>COST</th>
                <th>NUMBER OF YEARS</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>RPA LICENSING COSTS</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="rpaLicensingCost_cost"
                      value={state_operatingExpenses.rpaLicensingCost_cost}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="rpaLicensingCost_year"
                      value={state_operatingExpenses.rpaLicensingCost_year}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_operatingExpenses.rpaLicensingCost_cost *
                        state_operatingExpenses.rpaLicensingCost_year
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>AI LICENCING COSTS</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="aiLicensingCost_cost"
                      value={state_operatingExpenses.aiLicensingCost_cost}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      type="number"
                      name="aiLicensingCost_year"
                      value={state_operatingExpenses.aiLicensingCost_year}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_operatingExpenses.aiLicensingCost_cost *
                        state_operatingExpenses.aiLicensingCost_year
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>ONGOING INFRASTRUCTURE COSTS FOR OPERATION OF RPA BOTS</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="infraCost_cost"
                      value={state_operatingExpenses.infraCost_cost}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="infraCost_year"
                      value={state_operatingExpenses.infraCost_year}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_operatingExpenses.infraCost_cost *
                        state_operatingExpenses.infraCost_year
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>SUPPORT COSTS FOR OPERATION AND MAINTENANCE OF RPA BOTS</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="supportCost_cost"
                      value={state_operatingExpenses.supportCost_cost}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="supportCost_year"
                      value={state_operatingExpenses.supportCost_year}
                      onChange={handleoperatingExpenses}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_operatingExpenses.supportCost_cost *
                        state_operatingExpenses.supportCost_year
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="novisible"></td>
                <td className="novisible"></td>
                <td className="colorbg">Total Cost</td>
                <td className="center">
                  ${" "}
                  {state_operatingExpenses.rpaLicensingCost_cost *
                    state_operatingExpenses.rpaLicensingCost_year +
                    state_operatingExpenses.aiLicensingCost_cost *
                      state_operatingExpenses.aiLicensingCost_year +
                    state_operatingExpenses.infraCost_cost *
                      state_operatingExpenses.infraCost_year +
                    state_operatingExpenses.supportCost_cost *
                      state_operatingExpenses.supportCost_year +
                    state_capitalExpenses.initialDevelopmentCost +
                    state_capitalExpenses.costofAI}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* *
         *
         *  show only on mobile View
         */}
        <div className="nopcdisplay">
          <div>
            <Heading {...mediumdescription} content="RPA LICENSING COSTS" />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                {" "}
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="rpaLicensingCost_cost"
                    value={state_operatingExpenses.rpaLicensingCost_cost}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                {" "}
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    value={state_operatingExpenses.rpaLicensingCost_year}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                {" "}
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_operatingExpenses.rpaLicensingCost_cost *
                      state_operatingExpenses.rpaLicensingCost_year
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading {...mediumdescription} content="AI LICENCING COSTS" />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="aiLicensingCost_cost"
                    value={state_operatingExpenses.aiLicensingCost_cost}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    type="number"
                    name="aiLicensingCost_year"
                    value={state_operatingExpenses.aiLicensingCost_year}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_operatingExpenses.aiLicensingCost_cost *
                      state_operatingExpenses.aiLicensingCost_year
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="ONGOING INFRASTRUCTURE COSTS FOR OPERATION OF RPA BOTS"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="infraCost_cost"
                    value={state_operatingExpenses.infraCost_cost}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="infraCost_year"
                    value={state_operatingExpenses.infraCost_year}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_operatingExpenses.infraCost_cost *
                      state_operatingExpenses.infraCost_year
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="SUPPORT COSTS FOR OPERATION AND MAINTENANCE OF RPA BOTS"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="supportCost_cost"
                    value={state_operatingExpenses.supportCost_cost}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="supportCost_year"
                    value={state_operatingExpenses.supportCost_year}
                    onChange={handleoperatingExpenses}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_operatingExpenses.supportCost_cost *
                      state_operatingExpenses.supportCost_year
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="TOTAL OPERATING EXPENSES"
            />
            <Box {...row}>
              <Box {...col50}>
                {/* <Text content="TOTAL OPERATING EXPENSES" /> */}
              </Box>
              <Box {...col50} className="center">
                ${" "}
                {state_operatingExpenses.rpaLicensingCost_cost *
                  state_operatingExpenses.rpaLicensingCost_year +
                  state_operatingExpenses.aiLicensingCost_cost *
                    state_operatingExpenses.aiLicensingCost_year +
                  state_operatingExpenses.infraCost_cost *
                    state_operatingExpenses.infraCost_year +
                  state_operatingExpenses.supportCost_cost *
                    state_operatingExpenses.supportCost_year +
                  state_capitalExpenses.initialDevelopmentCost +
                  state_capitalExpenses.costofAI}
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      {/* Capital Expense */}
      <Container className="bottommargin">
        <Heading {...title} content="Fundamental Considerations" />
        <Box {...row}>
          <Box {...col50}>
            <Text content="Average Employee Cost Per Hour" />
          </Box>
          <Box {...col50}>
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="averageEmployeeCostPerHour"
                value={
                  state_fundamentalConsiderations.averageEmployeeCostPerHour
                }
                onChange={handlefundamentalConsiderations}
              ></input>
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text content="Employee Hours for One Iteration of the Process" />
          </Box>
          <Box {...col50}>
            {/* bind input */}
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="employeeHoursForOneIteration"
                value={
                  state_fundamentalConsiderations.employeeHoursForOneIteration
                }
                onChange={handlefundamentalConsiderations}
              ></input>
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text content="Number of Iterations per year" />
          </Box>
          <Box {...col50}>
            {/* bind input */}
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="numberOfIterationsPerYear"
                value={
                  state_fundamentalConsiderations.numberOfIterationsPerYear
                }
                onChange={handlefundamentalConsiderations}
              ></input>
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text
              content="Number of hours per year where FTEs can be reassigned to other tasks instead of being idle as they wait for peak time needs for a process
"
            />
          </Box>
          <Box {...col50}>
            {/* bind input */}
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="hoursPerYear"
                value={state_fundamentalConsiderations.hoursPerYear}
                onChange={handlefundamentalConsiderations}
              ></input>
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text content="Number of hours your FTEs work overtime in a year" />
          </Box>
          <Box {...col50}>
            {/* bind input */}
            <span className="dollar">
              ${" "}
              <input
                type="number"
                name="overtimeHours"
                value={state_fundamentalConsiderations.overtimeHours}
                onChange={handlefundamentalConsiderations}
              ></input>
            </span>
          </Box>
        </Box>
      </Container>
      {/* ORGANIZATIONAL SAVINGS*/}
      <Container className="bottommargin">
        <Heading {...description} content="ORGANIZATIONAL SAVINGS" />
        <div className="nomobile_display">
          <table className="table" id="costofowner">
            <thead>
              <tr>
                <th style={{ backgroundColor: "transparent" }}></th>
                <th>COST</th>
                <th>NUMBER OF YEARS</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>REDUCED LABOR COSTS</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="reducedLabourCost_cost"
                      value={state_organizationalSavings.reducedLabourCost_cost}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="reducedLabourCost_years"
                      value={
                        state_organizationalSavings.reducedLabourCost_years
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.reducedLabourCost_cost *
                        state_organizationalSavings.reducedLabourCost_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>PEAK TIME ACCOMMODATION</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="peakTimeAccomodation_cost"
                      value={
                        state_organizationalSavings.peakTimeAccomodation_cost
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="peakTimeAccomodation_years"
                      value={
                        state_organizationalSavings.peakTimeAccomodation_years
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.peakTimeAccomodation_cost *
                        state_organizationalSavings.peakTimeAccomodation_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>OVERTIME REDUCTION</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="overtimeReduction_cost"
                      value={state_organizationalSavings.overtimeReduction_cost}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="overtimeReduction_years"
                      value={
                        state_organizationalSavings.overtimeReduction_years
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.overtimeReduction_cost *
                        state_organizationalSavings.overtimeReduction_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>OTHER BENEFITS (May not be quantifiable)</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="otherBenefits_cost"
                      value={state_organizationalSavings.otherBenefits_cost}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="otherBenefits_years"
                      value={state_organizationalSavings.otherBenefits_years}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.otherBenefits_cost *
                        state_organizationalSavings.otherBenefits_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>RESKILLING AND UPSKILLING EMPLOYEES</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="reskilling_cost"
                      value={state_organizationalSavings.reskilling_cost}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="reskilling_years"
                      value={state_organizationalSavings.reskilling_years}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.reskilling_cost *
                        state_organizationalSavings.reskilling_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>BOOSTING EMPLOYEE SATISFACTION, REDUCING TURNOVER</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="boosting_cost"
                      value={state_organizationalSavings.boosting_cost}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="boosting_years"
                      value={state_organizationalSavings.boosting_years}
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.boosting_cost *
                        state_organizationalSavings.boosting_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>IMPROVED DATA QUALITY, WITH FEWER ERRORS</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="improvedDataQuality_cost"
                      value={
                        state_organizationalSavings.improvedDataQuality_cost
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="improvedDataQuality_years"
                      value={
                        state_organizationalSavings.improvedDataQuality_years
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.improvedDataQuality_cost *
                        state_organizationalSavings.improvedDataQuality_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td>IMPROVED REGULATORY COMPLIANCE</td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="improvedRegulatoryCompliance_cost"
                      value={
                        state_organizationalSavings.improvedRegulatoryCompliance_cost
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      name="improvedRegulatoryCompliance_years"
                      value={
                        state_organizationalSavings.improvedRegulatoryCompliance_years
                      }
                      onChange={handleorganizationalSavings}
                    ></input>
                  </span>
                </td>
                <td>
                  <span className="dollar">
                    ${" "}
                    <input
                      type="number"
                      readOnly
                      value={
                        state_organizationalSavings.improvedRegulatoryCompliance_cost *
                        state_organizationalSavings.improvedRegulatoryCompliance_years
                      }
                    ></input>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="novisible"></td>
                <td className="novisible"></td>
                <td className="colorbg">Total Cost</td>
                <td className="center">
                  ${" "}
                  {state_organizationalSavings.reducedLabourCost_cost *
                    state_organizationalSavings.reducedLabourCost_years +
                    state_organizationalSavings.peakTimeAccomodation_cost *
                      state_organizationalSavings.peakTimeAccomodation_years +
                    state_organizationalSavings.overtimeReduction_cost *
                      state_organizationalSavings.overtimeReduction_years +
                    state_organizationalSavings.otherBenefits_cost *
                      state_organizationalSavings.otherBenefits_years +
                    state_organizationalSavings.reskilling_cost *
                      state_organizationalSavings.reskilling_years +
                    state_organizationalSavings.boosting_cost *
                      state_organizationalSavings.boosting_years +
                    state_organizationalSavings.improvedDataQuality_cost *
                      state_organizationalSavings.improvedDataQuality_years +
                    state_organizationalSavings.improvedRegulatoryCompliance_cost *
                      state_organizationalSavings.improvedRegulatoryCompliance_years}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="nopcdisplay">
          <div>
            <Heading {...mediumdescription} content="REDUCED LABOR COSTS" />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="reducedLabourCost_cost"
                    value={state_organizationalSavings.reducedLabourCost_cost}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="reducedLabourCost_years"
                    value={state_organizationalSavings.reducedLabourCost_years}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.reducedLabourCost_cost *
                      state_organizationalSavings.reducedLabourCost_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading {...mediumdescription} content="PEAK TIME ACCOMMODATION" />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="peakTimeAccomodation_cost"
                    value={
                      state_organizationalSavings.peakTimeAccomodation_cost
                    }
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="peakTimeAccomodation_years"
                    value={
                      state_organizationalSavings.peakTimeAccomodation_years
                    }
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.peakTimeAccomodation_cost *
                      state_organizationalSavings.peakTimeAccomodation_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading {...mediumdescription} content="OVERTIME REDUCTION" />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="overtimeReduction_cost"
                    value={state_organizationalSavings.overtimeReduction_cost}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="overtimeReduction_years"
                    value={state_organizationalSavings.overtimeReduction_years}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.overtimeReduction_cost *
                      state_organizationalSavings.overtimeReduction_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="OTHER BENEFITS (May not be quantifiable)"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="otherBenefits_cost"
                    value={state_organizationalSavings.otherBenefits_cost}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="otherBenefits_years"
                    value={state_organizationalSavings.otherBenefits_years}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.otherBenefits_cost *
                      state_organizationalSavings.otherBenefits_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="RESKILLING AND UPSKILLING EMPLOYEES"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="reskilling_cost"
                    value={state_organizationalSavings.reskilling_cost}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="reskilling_years"
                    value={state_organizationalSavings.reskilling_years}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.reskilling_cost *
                      state_organizationalSavings.reskilling_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="BOOSTING EMPLOYEE SATISFACTION, REDUCING TURNOVER"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="boosting_cost"
                    value={state_organizationalSavings.boosting_cost}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="boosting_years"
                    value={state_organizationalSavings.boosting_years}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.boosting_cost *
                      state_organizationalSavings.boosting_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="IMPROVED DATA QUALITY, WITH FEWER ERRORS"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="improvedDataQuality_cost"
                    value={state_organizationalSavings.improvedDataQuality_cost}
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="improvedDataQuality_years"
                    value={
                      state_organizationalSavings.improvedDataQuality_years
                    }
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.improvedDataQuality_cost *
                      state_organizationalSavings.improvedDataQuality_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="IMPROVED REGULATORY COMPLIANCE"
            />
            <Box {...row}>
              <Box {...col50}>
                <Text content="cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="improvedRegulatoryCompliance_cost"
                    value={
                      state_organizationalSavings.improvedRegulatoryCompliance_cost
                    }
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Number of years" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    name="improvedRegulatoryCompliance_years"
                    value={
                      state_organizationalSavings.improvedRegulatoryCompliance_years
                    }
                    onChange={handleorganizationalSavings}
                  ></input>
                </span>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col50}>
                <Text content="Total cost" />
              </Box>
              <Box {...col50}>
                <span className="dollar">
                  ${" "}
                  <input
                    type="number"
                    readOnly
                    value={
                      state_organizationalSavings.improvedRegulatoryCompliance_cost *
                      state_organizationalSavings.improvedRegulatoryCompliance_years
                    }
                  ></input>
                </span>
              </Box>
            </Box>
          </div>

          <div>
            <Heading
              {...mediumdescription}
              content="TOTAL ORGANIZATIONAL SAVINGS"
            />
            <Box {...row}>
              <Box {...col50}>
                {/* <Text content="TOTAL ORGANIZATIONAL SAVINGS" /> */}
              </Box>
              <Box {...col50} className="center">
                ${" "}
                {state_organizationalSavings.reducedLabourCost_cost *
                  state_organizationalSavings.reducedLabourCost_years +
                  state_organizationalSavings.peakTimeAccomodation_cost *
                    state_organizationalSavings.peakTimeAccomodation_years +
                  state_organizationalSavings.overtimeReduction_cost *
                    state_organizationalSavings.overtimeReduction_years +
                  state_organizationalSavings.otherBenefits_cost *
                    state_organizationalSavings.otherBenefits_years +
                  state_organizationalSavings.reskilling_cost *
                    state_organizationalSavings.reskilling_years +
                  state_organizationalSavings.boosting_cost *
                    state_organizationalSavings.boosting_years +
                  state_organizationalSavings.improvedDataQuality_cost *
                    state_organizationalSavings.improvedDataQuality_years +
                  state_organizationalSavings.improvedRegulatoryCompliance_cost *
                    state_organizationalSavings.improvedRegulatoryCompliance_years}
              </Box>
            </Box>
          </div>
        </div>
      </Container>
      {/* TOTAL SAVINGS*/}
      <Container className="bottommargin">
        <Heading {...title} content="COSTS-SAVINGS CALCULATION" />
        <Box {...row}>
          <Box {...col50}>
            <Text content="TOTAL COSTS" />
          </Box>
          <Box {...col50}>
            <span className="dollar">
              ${" "}
              <input
                className="noborder"
                readOnly
                value={
                  state_operatingExpenses.rpaLicensingCost_cost *
                    state_operatingExpenses.rpaLicensingCost_year +
                  state_operatingExpenses.aiLicensingCost_cost *
                    state_operatingExpenses.aiLicensingCost_year +
                  state_operatingExpenses.infraCost_cost *
                    state_operatingExpenses.infraCost_year +
                  state_operatingExpenses.supportCost_cost *
                    state_operatingExpenses.supportCost_year +
                  state_capitalExpenses.initialDevelopmentCost +
                  state_capitalExpenses.costofAI
                }
              />
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text content="TOTAL SAVINGS" />
          </Box>
          <Box {...col50}>
            <span className="dollar">
              ${" "}
              <input
                className="noborder"
                readOnly
                value={
                  state_organizationalSavings.reducedLabourCost_cost *
                    state_organizationalSavings.reducedLabourCost_years +
                  state_organizationalSavings.peakTimeAccomodation_cost *
                    state_organizationalSavings.peakTimeAccomodation_years +
                  state_organizationalSavings.overtimeReduction_cost *
                    state_organizationalSavings.overtimeReduction_years +
                  state_organizationalSavings.otherBenefits_cost *
                    state_organizationalSavings.otherBenefits_years +
                  state_organizationalSavings.reskilling_cost *
                    state_organizationalSavings.reskilling_years +
                  state_organizationalSavings.boosting_cost *
                    state_organizationalSavings.boosting_years +
                  state_organizationalSavings.improvedDataQuality_cost *
                    state_organizationalSavings.improvedDataQuality_years +
                  state_organizationalSavings.improvedRegulatoryCompliance_cost *
                    state_organizationalSavings.improvedRegulatoryCompliance_years
                }
              />
            </span>
          </Box>
        </Box>
        <Box {...row}>
          <Box {...col50}>
            <Text content="COSTS LESS SAVINGS" />
          </Box>
          <Box {...col50}>
            <span className="dollar">
              ${" "}
              <input
                className="noborder"
                readOnly
                value={
                  state_organizationalSavings.reducedLabourCost_cost *
                    state_organizationalSavings.reducedLabourCost_years +
                  state_organizationalSavings.peakTimeAccomodation_cost *
                    state_organizationalSavings.peakTimeAccomodation_years +
                  state_organizationalSavings.overtimeReduction_cost *
                    state_organizationalSavings.overtimeReduction_years +
                  state_organizationalSavings.otherBenefits_cost *
                    state_organizationalSavings.otherBenefits_years +
                  state_organizationalSavings.reskilling_cost *
                    state_organizationalSavings.reskilling_years +
                  state_organizationalSavings.boosting_cost *
                    state_organizationalSavings.boosting_years +
                  state_organizationalSavings.improvedDataQuality_cost *
                    state_organizationalSavings.improvedDataQuality_years +
                  state_organizationalSavings.improvedRegulatoryCompliance_cost *
                    state_organizationalSavings.improvedRegulatoryCompliance_years -
                  (state_operatingExpenses.rpaLicensingCost_cost *
                    state_operatingExpenses.rpaLicensingCost_year +
                    state_operatingExpenses.aiLicensingCost_cost *
                      state_operatingExpenses.aiLicensingCost_year +
                    state_operatingExpenses.infraCost_cost *
                      state_operatingExpenses.infraCost_year +
                    state_operatingExpenses.supportCost_cost *
                      state_operatingExpenses.supportCost_year +
                    state_capitalExpenses.initialDevelopmentCost +
                    state_capitalExpenses.costofAI)
                }
              />
            </span>
          </Box>
        </Box>
      </Container>
    </CalculatorWrapper>
  );
};

CalculatorOthersection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  textArea: PropTypes.object,
  description: PropTypes.object,
};

CalculatorOthersection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    // justifyContent: 'center',
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // ml: '-15px',
    // mr: '-15px',
    mt: "10px",
  },
  col50: {
    width: ["50%"],
  },

  textArea: {
    margin: "20px 15px",
    padding: "30px 15px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    className: "misc_contianer",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#0f2137",
    letterSpacing: "0.015em",
    mb: "40px",
    mt: "15px",
    pt: "15px",
    textAlign: "center",
    className: "underline",
  },
  description: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#0f2137",
    letterSpacing: "0.015em",
    color: "#252525",
    mb: "20px",
    pt: "15px",
    textAlign: "left",
  },
  mediumdescription: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#0f2137",
    letterSpacing: "0.015em",
    color: "#252525",
    mb: "20px",
    pt: "15px",
    textAlign: "left",
    className: "underline",
  },
  smalldescription: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#0f2137",
    letterSpacing: "0.015em",
    color: "#252525",
    mb: "20px",
    textAlign: "left",
  },
};

export default CalculatorOthersection;
